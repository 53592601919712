import "./App.css";
import "./planet.js";

function App() {
  return (
    <div>
      <div id="topText">
        <code className="justify">Bagus Suprapta</code>
        <br />
        <code>bagus@slickeel.com</code>
      </div>

      <pre id="planet"></pre>

      <div id="bottomText">
        <code>software1/0engineer</code>
      </div>
    </div>
  );
}

export default App;
