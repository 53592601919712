let width = 60;
let height = 30;
let chars = ".,-~:;=!*#⚝✿▄█▀█";
let A = 1,
  B = 1;

const renderPlanetWithRing = () => {
  let b = [],
    z = [];
  A += 0.2;
  B += 0.1;

  for (let k = 0; k < width * height; k++) {
    b[k] = k % width === width - 1 ? "\n" : " ";
    z[k] = 0;
  }

  for (let j = 0; j < 6.28; j += 0.07) {
    for (let i = 0; i < 6.28; i += 0.02) {
      let sini = Math.sin(i),
        cosi = Math.cos(i),
        cosj = Math.cos(j),
        sinj = Math.sin(j),
        cosA = Math.cos(A),
        sinA = Math.sin(A),
        cosB = Math.cos(B),
        sinB = Math.sin(B);

      let D = 1 / (sini * cosA + sinj * cosi * sinA + 2);
      let t = sini * sinA - sinj * cosi * cosA;

      let x = Math.floor(width / 2 + (width / 3) * D * (cosi * cosB - t * sinB)),
        y = Math.floor(height / 2 + (height / 2) * D * (cosi * sinB + t * cosB)),
        o = x + width * y;

      let L = cosi * cosj * sinB - sini * cosj * cosA - sinj * sinA - cosi * sinj * cosB;

      let N = Math.floor(8 * L);

      if (height > y && y > 0 && width > x && x > 0 && D > z[o]) {
        z[o] = D;
        b[o] = chars[N > 0 ? N : 0];
      }
    }
  }

  for (let theta = 0; theta < 6.28; theta += 0.07) {
    let cosTheta = Math.cos(theta),
      sinTheta = Math.sin(theta),
      ringRadius = 10;

    let xRing = Math.floor(width / 2 + ringRadius * cosTheta * Math.cos(B)),
      yRing = Math.floor(height / 2 + ringRadius * sinTheta);

    let ringIndex = xRing + width * yRing;

    if (height > yRing && yRing > 0 && width > xRing && xRing > 0) {
      b[ringIndex] = "-";
    }
  }

  document.getElementById("planet").innerText = b.join("");
};

setInterval(renderPlanetWithRing, 50);
